import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

// import { Badge } from 'reactstrap';
import img1 from "../images/rockpop1.jpg";
import img2 from "../images/rockpop2.jpg";
import img3 from "../images/rockpop3.jpg";

const PortfolioPage = () => (
  <Layout>
    <SEO 
      title="Javier Suzuki | Design & Front-end Development Portfolio - Rock&Pop Blogs"
      keywords={[`javier`, `suzuki`, `web`, `ui`, `ux`, `design`, `front-end`, `development`, `wordpress`, `blog`]}
    />
    <div className="portfolio-header">
      <h1>Rock&Pop 95.9</h1>
      <h5>Wordpress Multisite (Blogs)</h5>
      <p>Design and front-end development of blogs & banners for Rock&Pop 95.rockpop radio station.</p>
      <p>Built with Wordpress Multisite.</p>
    </div>

    <div className="portfolio-content">
      <figure className="portfolio-sample">
        <figcaption className="figheading">Blogs Theme</figcaption>
        <img src={img1} alt=""/>
      </figure>
      <figure className="portfolio-sample">
        <figcaption className="figheading">Blogs Banners</figcaption>
        <img src={img2} alt=""/>
      </figure>
      <figure className="portfolio-sample">
        <figcaption className="figheading">Radio Station Landing Page</figcaption>
        <img src={img3} alt=""/>
      </figure>
    </div>

    <div className="portfolio-footer">
      {/* <a className="btn btn-primary" href="https://wushka.com">View Project</a> */}
    </div>

  </Layout>
)

export default PortfolioPage
